body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.morr {
  background-size: 'contain';
  background-position-y: center;
}

.MuiTablePagination-root {
  display: none;
}